import React, { useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { SparklesIcon } from '@heroicons/react/24/solid'
import Stack from '@mui/material/Stack'
import { JOB_TYPE } from 'types/graphql'

import { routes } from '@redwoodjs/router'

import Button from 'src/components/Library/Button/Button'
import Tab from 'src/components/Library/Tab/Tab'
import Tabs from 'src/components/Library/Tabs/Tabs'
import { GetQueuedJobs } from 'src/components/QueuerDashboard/QueuerDashboardCell'
import QueuerDashboardDataSourceStatusSection from 'src/components/QueuerDashboard/QueuerDashboardDataSourceStatusSection'
import QueuerDashboardQueuedJobsSection from 'src/components/QueuerDashboard/QueuerDashboardQueuedJobsSection'
import QueuerDashboardRecurringJobsSection from 'src/components/QueuerDashboard/QueuerDashboardRecurringJobsSection'
import QueuerDashboardSwitchboardCell from 'src/components/QueuerDashboard/QueuerDashboardSwitchboardCell'
import {
  TabId,
  tabIdSanitise,
} from 'src/pages/QueuerDashboardPage/QueuerDashboardPage'

interface QueuerProps {
  queuerJobTypes: JOB_TYPE[]
  queuedJobs: GetQueuedJobs
  refetch: () => Promise<unknown>
  tabId?: TabId
}

const QueuerDashboardMainSection: React.FC<QueuerProps> = ({
  queuerJobTypes,
  queuedJobs,
  refetch,
  tabId = TabId.CurrentJobs,
}) => {
  const [isRefetching, setIsRefetching] = useState(false)
  const [tabValue, setTabValue] = useState(tabId)
  const apolloClient = useApolloClient()

  tabIdSanitise(tabValue)

  const handleRefetch = async () => {
    setIsRefetching(true)
    await apolloClient.refetchQueries({
      include: ['FindQueuerDashboardQuery', 'FindQueuerSwitchboardQuery'],
    })
    await refetch()
    setIsRefetching(false)
  }

  const handleChangeTab = (event: React.SyntheticEvent, tabId: TabId) => {
    event.preventDefault()
    tabId = tabIdSanitise(tabId)
    const urlPath = routes.queuerDashboardTab({ tabId })
    window.history.pushState({}, '', urlPath)
    setTabValue(tabId)
  }

  return (
    <>
      <Stack direction="row" spacing={2} className="m-4 justify-between">
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          style={{ borderBottom: 'none' }}
        >
          <Tab label="Current Jobs" data-testid="current-jobs-tab" />
          <Tab label="Recurring Jobs" data-testid="recurring-jobs-tab" />
          <Tab label="Job History" data-testid="job-history-tab" />
          <Tab
            label="Client Switchboard"
            data-testid="client-switchboard-tab"
          />
        </Tabs>
        <Stack direction={'row'} spacing={2} className="justify-between">
          <Button
            fullWidth={false}
            size={'small'}
            startIcon={<SparklesIcon className="h-5 w-5" />}
            className="min-w-[0] rounded-lg bg-blue-500 text-white hover:bg-blue-600"
            loading={isRefetching}
            onClick={handleRefetch}
            buttonDataTestId={`main-section-refresh-button-isRefetching-${isRefetching}`}
          >
            Refresh
          </Button>
        </Stack>
      </Stack>

      {tabValue === TabId.CurrentJobs && (
        <QueuerDashboardQueuedJobsSection queuedJobs={queuedJobs} />
      )}
      {tabValue === TabId.RecurringJobs && (
        <QueuerDashboardRecurringJobsSection queuedJobs={queuedJobs} />
      )}
      {tabValue === TabId.JobHistory && (
        <QueuerDashboardDataSourceStatusSection
          queuerJobTypes={queuerJobTypes}
          queuedJobs={queuedJobs}
        />
      )}
      {tabValue === TabId.Switchboard && <QueuerDashboardSwitchboardCell />}
    </>
  )
}

export default QueuerDashboardMainSection
